@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');

:root {
  --f-p : 'Poppins', sans-serif;

  --c-p : #fee6974d;
  --c-w : #FFFFFF;
  --c-b : #232323;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.app {
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: var(--c-w);
}

.app__main {
  width: 100%;
  height: 100%;
}

@media all and (max-width : 600px) {
  * {
    cursor: default;
  }
}