@import "/src/index.css";

.app__navbar {
    width: 100%;
    max-width: 100%;
    height: 82px;
    background-color: var(--c-w);
    box-shadow: 0 2px 4px 1px #23232315;
}

.nav__center {
    width: 1200px;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.nav__logo, .nav__logo a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    font-family: 'Times New Roman', Times, serif;
}

.nav__logo img {
    width: 100px;
    mix-blend-mode: multiply;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
}

.nav__logo a p {
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
}

.nav__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.menu__links, .icon__links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.menu__links a {
    font-family: var(--f-p);
    color: #383838;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    transition: color 0.3s ease;
    text-align: center;
}

.icon__links a {
    font-size: 20px;
    color: #383838;
    transition: color 0.3s ease;
    text-align: center;
}

.menu__links a:nth-child(1):hover, .menu__links a:nth-child(1).selected {
    font-weight: 600;
    color: var(--c-b);
}

.menu__links a:hover, .icon__links a:hover {
    color: var(--c-b);
}

.btn__cta {
    padding: 0.5rem 1.2rem;
    font-family: var(--f-p);
    color: var(--c-w);
    background-color: var(--c-b);
    border: 1.6px solid var(--c-w);
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    transition: color 0.3s linear, background-color 0.3s linear, border 0.3s linear;
}

.btn__cta:hover {
    background-color: var(--c-w);
    color: var(--c-b);
    border: 1.6px solid var(--c-b);
}

.nav__smscreen {
    display: none;
}

/* Focus Visible - Keyboard Accessibility */

.nav__logo a:focus-visible, .menu__links a:focus-visible, .icon__links a:focus-visible {
    outline: 2px solid #424242fa;
    outline-offset: 10px;
    border-radius: 4px;
}

/* Focus Visible - Keyboard */


@media all and (max-width : 990px) {

    .app__navbar {
        position: relative;
    }

    .nav__menu {
        position: absolute;
        width: 280px;
        max-width: 100%;
        height: 100vh;
        top: 0;
        left: -900px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        padding: 2rem 0.6rem;
        background-color: #232323;
        box-shadow: 4px 0 2px 4px #23232310;
        transition: left 0.3s linear;
        z-index: 9999;
    }

    .nav__menu.menu__open {
        left: 0;
    }

    .menu__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.4rem;
    }

    .menu__links a, .icon__links a {
        color: #c6c6c6;
    }

    .nav__logo a p {
        font-size: 1.4rem;
    }

    .menu__links a:nth-child(1):hover, .menu__links a:nth-child(1).selected {
        color: #fcfcfc;
    }

    .menu__links a:hover, .icon__links a:hover {
        color: #fcfcfc;
    }
    
    .nav__smscreen {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav__smscreen button {
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: none;
        background-color: white;
        cursor: pointer;
        transition: transform 0.3s linear;
    }

    .nav__smscreen button:focus-visible {
        outline: 2px solid #424242fa;
        outline-offset: 5px;
        border-radius: 4px;
        text-decoration: underline;
    }

    .nav__smscreen button.menu__open {
        transform: rotate(-90deg);
    }

}

@media all and (max-width : 370px) {
    .nav__menu {
        width: 210px;
    }

    .nav__logo a p {
        display: none;
    }
}