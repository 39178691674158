@import "/src/index.css";

.app__home {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
}

/* Section 1 - Services */

.home__section1 {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/Hero-Small.png');
    background-size: cover;
    background-position: center;
    background-color: #232323;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.home__section1::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: 0;
    animation: pulse 2.5s infinite;
    background-color: white;
}

.home__section1 img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.home__section1 img.img__loaded {
    opacity: 1;
}

.section1__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #232323a0;
    filter: grayscale(50%);
}

.section1__center {
    width: 1200px;
    max-width: 100%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 4rem;
    position: relative;
    z-index: 999;
}

.section1__subhead {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.8rem;
}

.section1__subhead h2 {
    color: #fff;
    font-family: var(--f-p);
    font-size: 32px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.section1__subhead p {
    width: 700px;
    max-width: 100%;
    margin: auto;
    color: #fff;
    font-family: var(--f-p);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: normal;
}

.section1__services {
    width: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
}

.section1__services h3 {
    color: #ffffff;
    text-align: center;
    font-family: var(--f-p);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.some__popular {
    width: 580px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.2rem 0.8rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.some__popular a {
    padding: 0.5rem 0.8rem;
    font-family: var(--f-p);
    font-size: 14px;
    color: #fff;
    background-color: rgba(28, 28, 28, 0.05);
    border-radius: 24px;
    border: 1px solid #fff;
    outline: none;
}

.some__popular > *:focus-visible {    
    border: 2px solid #fefefefa;
}

.section1__cta {
    width: 100%;
    margin: 1.6rem auto 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 1.2rem;
}

.section1__cta a, .section1__cta button {
    padding: 0.5rem 1.2rem;
    font-family: var(--f-p);
    font-size: 17px;
    text-align: center;
    border-radius: 8px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    color: var(--c-b);
    background-color: #FFFBEF;
    border : 1px solid #FFFBEF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    outline: none;
}

.section1__cta a:hover, .section1__cta button:hover {
    color: #232323;
    background-color: #ffffff;
}

.section1__cta > *:focus-visible {
    background-color: #c69912;
    color: #000000;
}

.service__icon {
    animation: upDown 4s linear infinite;
}

@keyframes upDown {
    
    0% {
        transform: translateY(0px);
    }

    33% {
        transform: translateY(4px);
    }

    66%, 100% {
        transform: translateY(0px);
    }

}

.section1__stats {
    width: 540px;
    max-width: 96%;
    margin: 1rem auto;
    background-color: #FFFBEF;
    border: 1.2px solid #000;
    box-shadow: 0 0 4px #000000a0;
    border-radius: 8px;
    padding: 1.2rem 1.6rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 1.6rem;

    position: absolute;
    bottom: -94px;
}

.stats {
    width: 180px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #000;
    padding: 0.8rem;
    border-radius: 8px;
    gap: 0.4rem;
}

.stats span {
    font-family: var(--f-p);
    font-size: 28px;
    line-height: normal;
    text-align: center;
    color: #fff;
}

.stats p {
    font-family: var(--f-p);
    font-size: 17px;
    color: #ddd;
    text-align: center;
    list-style: 24px;
}

/* Section 1 - Services */

/* Section 2 - About Us */

.home__section2 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: #FFF;
    margin-top: 3rem;
}

.section2__center {
    width: 1200px;
    max-width: 100%;
    margin: 3rem auto 2rem;
    padding: 1rem;
    height: 100%;
    background-color: #FFF;
}

.section2__center h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.section2__main {
    margin: 2rem auto;
    width: 100%;
    background-color: var(--c-p);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}

.img__load {
    height: 100%;
    background-image: url('../../assets/homeAbout-Small.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
}

.img__load::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: -1;
    animation: pulse 2.5s infinite;
    background-color: white;
}

@keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }

.img__load img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.img__load img.img__loaded {
    opacity: 1;
}

.section2__about {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
}

.section2__about p {
    width: 100%;
    text-align: left;
    font-size: 0.9rem;
    font-family: var(--f-p);
    margin: auto;
}

.section2__about p::first-letter {
    font-size: 1.8rem;
    font-weight: 600;
}

/* Section 2 - About Us */

/* Section 3 - Our Partners */



/* Section 3 - Our Partners */

/* Section 4 - Why Us & Services */

.home__section4 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: var(--c-p);
}

.section4__center {
    width: 1200px;
    max-width: 100%;
    margin: auto;
    padding: 3rem 1rem;
}

.why__us {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.2rem;
}

.why__us h2, .services h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.why__us p {
    width: 600px;
    max-width: 100%;
    height: 100%;
    color: #353535;
    font-family: var(--f-p);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.services {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin: 3rem auto 2rem;
}

.all__services {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem 7rem;
    flex-wrap: wrap;
    margin: 1rem auto;
}

.each__service {
    width: 150px;
    height: 176px;
    background-color: var(--c-b);
    display: flex;
    padding: 1.8rem 0.4rem;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.8rem;
    border-radius: 12px;
    color: var(--c-w);
    border: 1px solid var(--c-p);
    transition: all 0.3s linear;
}

.each__service:hover, .each__service:focus-visible {
    background-color: var(--c-w);
    border: 1px solid var(--c-b);
    outline: 2px solid var(--c-b);
    text-decoration: underline;
    color: var(--c-b);
}

.each__service img {
    width: 80px;
}

.each__service p {
    font-family: var(--f-p);
    font-size: 14px;
    text-align: center;
}

.each__service:hover > p {
    text-decoration: underline;
}

/* Section 4 - Why Us & Services */

/* Section 5 - FAQ's */

.home__section5 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: #FFF;
}

.section5__center {
    width: 1200px;
    max-width: 100%;
    margin: 3rem auto;
    padding: 1rem;
}

.section5__center h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.all__faqs {
    margin: 2rem auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
}

.each__faq {
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.faq__question {
    width: 100%;
    padding: 0.8rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1.2px solid #232323;
    border-radius: 8px;
    gap: 0.6rem;
    cursor: pointer;
    color: #232323;
    background-color: #FFFFFF;
    transition: color 0.3s linear, background-color 0.3s linear;
}

.faq__question:focus-visible {
    outline: 2px solid #424242fa;
    border-radius: 4px;
}

.faq__question.open__answer {
    background-color: #232323;
    color: #FFFFFF;
}

.faq__question p {
    font-family: var(--f-p);
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    width: 100%;
}

.faq__question span {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s linear;
}

.faq__question span.open__answer {
    transform: rotate(-180deg);
}

.faq__answer {
    width: 100%;
    padding: 0.6rem 1rem;
    display: none;
}

.faq__answer.open__answer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1.2px solid #232323;
    border-radius: 8px;
    gap: 0.6rem;
}

.faq__answer p {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    font-family: var(--f-p);
}

/* Section 5 - FAQ's */

/* Section 6 - Testimonials */

.home__section6 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #FFF;
}

.section6__center {
    width: 1200px;
    max-width: 100%;
    margin: 3rem auto;
    padding: 1rem;
    background-color: #FFF;
}

.section6__center h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.section6__main {
    width: 100%;
    margin: 3rem auto 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
}

.each__testimonial {
    width: 360px;
    height: 100%;
    background-color: #FFF;
    box-shadow: 0 0 2px #232323a0;
    padding: 1.2rem 1rem 1.2rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    flex-direction: column;
}

.testimonial__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.4rem;
}

.testimonial__detail img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 1px solid #232323a0;
}

.testimonial__detail p {
    font-family: var(--f-p);
    font-size: 17px;
    margin-top: 0.8rem;
    font-weight: 400;
    text-align: center;
}

.testimonial__detail p:nth-child(3) {
    font-size: 15px;
    margin-top: 0;
    color: #353535;
}

.testimonial__review {
    width: 100%;
}

.testimonial__review p {
    width: 100%;
    color: #232323;
    font-family: var(--f-p);
    font-size: 14px;
    font-weight: 300;
}

/* Section 6 - Testimonials */

/* Section 7 - Our Team */

.home__section7 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #FFFBEF;
}

.section7__center {
    width: 1200px;
    max-width: 100%;
    height: 100%;
    margin: 2rem auto 0;
    padding: 1rem;
}

.section7__center h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.section7__main {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3.2rem 1.6rem;
    margin: 2rem 0;
}

.each__member {
    width: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.9rem;
}

.each__member img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    object-position: top;
    border-radius: 12px;
    -webkit-user-drag: none;
    user-select: none;
    filter: drop-shadow(0 0 4px #232323b0);
}

.member__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}

.member__details p {
    color: #232323;
    font-family: var(--f-p);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.member__socials {
    width: 100%;
    margin-top: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
}

.member__socials a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.member__socials a:focus-visible {
    outline: 2px solid #424242fa;
    border-radius: 4px;
    outline-offset: 4px;
}

/* Section 7 - Our Team */

/* Section 8 - Subscribe Now */

.home__section8 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #FFF;
}

.section8__center {
    width: 1200px;
    max-width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #FFF;
}

.section8__center h2 {
    color: #232323;
    font-family: var(--f-p);
    font-size: 32px;
    font-style: normal;
    font-weight: 580;
    line-height: normal;
    text-align: center;
}

.section8__main {
    width: 600px;
    max-width: 100%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.section8__main input {
    width: 90%;
    padding: 0.7rem 1rem;
    border: 1px solid #232323;
    border-radius: 8px;
    font-family: var(--f-p);
    font-size: 17px;
    outline: none;
}

.section8__main button {
    padding: 0.5rem 1.2rem;
    font-family: var(--f-p);
    color: var(--c-w);
    background-color: var(--c-b);
    border: 1.6px solid var(--c-b);
    font-size: 17px;
    text-align: center;
    border-radius: 8px;
    transition: color 0.3s linear, background-color 0.3s linear, border 0.3s linear;
    cursor: pointer;
}

.section8__main button:hover {
    background-color: var(--c-w);
    color: var(--c-b);
}

/* Section 8 - Subscribe Now */

/* ********************************************************** */

/* Media Queries */

@media all and (max-width : 1024px) {

    /* Section 1 - Services Search */

    .home__section1 {
        min-height: 100%;
        height: 100%;
    }

    /* Section 1 - Services Search */

    /* Section 3 - Why Us & Services */

    .all__services {
        gap: 2rem;
    }

    /* Section 3 - Why Us & Services */

    /* Section 7 - Our Team */

    .section7__main {
        justify-content: center;
        gap: 1.6rem 1.2rem;
    }

    /* Section 7 - Our Team */
}

@media all and (max-width: 990px) {
    /* Section 2 - About Us */

    .section2__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .img__load {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .section2__about {
        width: 100%;
        padding: 2rem 1rem;
    }

    /* Section 2 - About Us */
}

@media all and (max-width : 800px) {
    /* Section 6 - Testimonials */

    .section6__main {
        justify-content: center;
    }

    .each__testimonial {
        width: 320px;
    }

    /* Section 6 - Testimonials */
}

@media all and (max-width : 720px) {

    /* Section 6 - Testimonials */

    .section6__main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 1rem;
        margin: 2rem auto;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .section6__main::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .each__testimonial {
        flex: 0 0 auto;
        width: 320px;
        margin: 1rem auto;
    }

    /* Section 6 - Testimonials */

    /* Section 7 - Our Team */

    .section7__main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.2rem;
        flex-wrap: nowrap;
        padding: 0 1rem;
        overflow-x: scroll;
    }

    .section7__main::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .each__member {
        flex: 0 0 auto;
        margin: 1rem auto;
    }

    /* Section 7 - Our Team */
}

@media all and (max-width: 500px) {
    
    /* Section 1 - Services */
    
    .section1__subhead h2 {
        font-size: 28px;
    }

    .section1__subhead p {
        font-size: 17px;
    }

    .section1__services h3 {
        font-size: 15px;
    }

    .some__popular {
        gap: 1rem 0.6rem;
    }

    .some__popular button {
        padding: 0.4rem 0.7rem;
        font-size: 14px;
    }

    .section1__cta button, .section1__cta a {
        font-size: 16px;
    }

    /* Section 1 - Services */

    /* Section 2 - About Us */

    .section2__center h2 {
        font-size: 28px;
    }

    .section2__about p {
        font-size: 0.9rem;
    }

    /* Section 2 - About Us */

    /* Section 3 - Why Us & Services */

    .all__services {
        gap: 1.2rem;
    }
    
    .each__service {
        width: 140px;
        height: 160px;
        padding: 1.4rem 0.4rem;
    }

    .each__service img {
        width: 68px;
    }

    .each__service p {
        font-size: 13px;
    }

    /* Section 3 - Why Us & Services */

    /* Section 5 - FAQ's */

    .section5__center h2 {
        font-size: 28px;
    }

    .faq__question p {
        font-size: 16px;
        font-weight: 400;
    }

    .faq__answer p {
        font-size: 14px;
        font-weight: 300;
    }

    /* Section 5 - FAQ's */

    /* Section 6 - Testimonials */

    .section6__center h2 {
        font-size: 28px;
    }

    .section6__main {
        gap: 1rem;
    }

    .each__member {
        width: 240px;
    }

    .each__member img {
        height: 240px;
    }

    /* Section 6 - Testimonials */

    /* Section 7 - Our Team */

    .section7__center h2 {
        font-size: 28px;
    }

    .section7__main {
        gap: 1rem;
    }

    .each__testimonial {
        flex: 0 0 auto;
        width: 280px;
        margin: 1rem auto;
    }

    /* Section 7 - Our Team */

    /* Section 8 - Subscribe Letter */

    .section8__center h2 {
        font-size: 28px;
    }

    .section8__main input {
        font-size: 16px;
        padding: 0.6rem 0.9rem;
    }

    .section8__main button {
        font-size: 16px;
    }

    /* Section 8 - Subscribe Letter */
}

@media all and (max-width : 473px) {

    /* Section 1 */

    .section1__stats {
        bottom: -200px;
        padding: 1rem;
        display: flex;
        gap: 1rem;
    }

    /* Section 1 */
    
    /* Section 2 */

    .home__section2 {
        margin-top: 1rem;
    }

    /* Section 2 */

}

@media all and (max-width : 350px) {

    /* Section 3 - Why Us & Services */

    .all__services {
        gap: 1rem;
    }
    
    .each__service {
        width: 134px;
        height: 156px;
        padding: 1.4rem 0.4rem;
    }

    /* Section 3 - Why Us & Services */
}

@media all and (max-width : 333px) {

    /* Section 3 - Why Us & Services */
    
    .each__service {
        width: 128px;
        height: 156px;
        padding: 1.4rem 0.4rem;
    }

    /* Section 3 - Why Us & Services */
}
