@import "/src/index.css";

.app__consult {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: #FFF;
}

/* Section 1 - Information */

.consult__section1 {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.section1__center {
    width: 700px;
    max-width: 100%;
    height: 100%;
    padding: 2rem 1rem;
    margin: 1rem auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.section1__center h1 {
    font-family: var(--f-p);
    font-size: 32px;
    color: #232323;
    line-height: normal;
    text-align: center;
}

.section1__center p {
    font-family: var(--f-p);
    width: 94%;
    text-align: center;
    font-size: 18px;
    color: #232323;
}

/* Section 1 - Information */

/* Section 2 - Form */

.consult__section2 {
    width: 100%;
    max-width: 100%;
    height: 100%;

    margin: 1rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

/* Section 2 - Form */

/* *************************************************************** */

/* Media Queries */

@media all and (max-width : 500px) {
    
    /* Section 1 - Information */
    
    .section1__center h1 {
        font-size: 26px;
    }

    .section1__center p {
        font-size: 15px;
    }

    /* Section 1 - Information */

    /* Section 2 - Form */

    .section2__center h2 {
        font-size: 26px;
    }

    .form__inputs input, .form__inputs select, .form__inputs textarea {
        font-size: 15px;
        padding: 0.6rem 0.9rem;
    }

    .section2__form > button {
        padding: 0.6rem 1rem;
        font-size: 15px;
    }

    /* Section 2 - Form */
    
}